import * as React from "react"

const AboutComponent = () => (
    <>
    <h1>About</h1>
    Sabrina Ko is a Philadelphia-based poet + programmer. She grew up in northern NJ and studied Creative Writing, Asian/Pacific/American Studies, and Computer Science at Smith College. Her poetry aims to explore the intersections and interconnectivity of subjects such as Korean diaspora/culture, nature, technology, and Buddhism. Sabrina's work is forthcoming in Tiny Seed Literary Journal.
    </>
)

export default AboutComponent
